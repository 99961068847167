import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import type { ApexMatch } from "@/data-models/apex-match.mjs";
import DataTable from "@/shared/DataTable.jsx";
import { debouncedUpdateRoute } from "@/shared/Profile.jsx";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { formatToFixedNumber, formatToPercent } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function MatchTileScoreboard({
  match,
  profileId,
}: {
  match: ApexMatch;
  profileId: string;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { currentPath, searchParams, state: routeState } = useRoute();
  const state = useSnapshot(readState);
  const profiles = state.apex.profiles;
  const player = useMemo(
    () => match.playerMatchStats.find((i) => i.platformId === profileId),
    [match.playerMatchStats, profileId],
  );
  const teammates = useMemo(() => {
    return match.playerMatchStats.filter(
      (i) => i.team.apexId === player.team.apexId,
    );
  }, [match.playerMatchStats, player.team.apexId]);
  const cols = useMemo(
    () => [
      {
        display: t("fortnite:match.mySquad", "My squad"),
        align: "left",
        isStat: false,
        className: "color-turq",
      },
      {
        display: t("common:stats.kills", "Kills"),
        align: "center",
        isStat: true,
      },
      {
        display: t("common:stats.damage", "Damage"),
        align: "center",
        isStat: true,
      },
      {
        display: t("common:stats.accuracy", "Accuracy"),
        align: "center",
        isStat: true,
      },
      {
        display: t("common:stats.hsPercent", "HS%"),
        align: "center",
        isStat: true,
      },
    ],
    [t],
  );
  const rows = useMemo(() => {
    return teammates.map(
      ({ platformId, damage_done, kills, hits, shots, headshots }) => {
        const profile = profiles[platformId];
        const accuracy = sanitizeNumber(hits / shots);
        const accuracyHs = sanitizeNumber(headshots / hits);
        const username = profile?.username ?? t("common:teammate", "Teammate");
        return [
          {
            display: username,
            value: username,
            link: `/apex/profile/${platformId}`,
          },
          {
            display: formatToFixedNumber(language, kills),
            value: kills,
          },
          {
            display: formatToFixedNumber(language, damage_done),
            value: damage_done,
          },
          {
            display: formatToPercent(language, accuracy),
            value: accuracy,
          },
          {
            display: formatToPercent(language, accuracyHs),
            value: accuracyHs,
          },
        ];
      },
    );
  }, [language, profiles, t, teammates]);
  useEffect(() => {
    // This allows the profile page to fetch the player's teammates (if any)
    if (teammates.length) {
      const next = { ...routeState };
      next.transient ||= {};
      next.transient.matchTilePlayers = next.transient.matchTilePlayers ?? {};
      let isUpdate = false;
      for (const i of teammates) {
        if (next.transient.matchTilePlayers[i.platformId]) continue;
        next.transient.matchTilePlayers[i.platformId] = true;
        if (!isUpdate) isUpdate = true;
      }
      if (isUpdate) debouncedUpdateRoute(currentPath, searchParams, next);
    }
  }, [currentPath, routeState, searchParams, teammates]);
  return <DataTable cols={cols} rows={rows} sortCol={1} sortColTiebreak={2} />;
}
